import React from 'react'
import { useSearchContext } from '@/contexts/search'
import BathroomIcon from 'icons/bathroom.svg'
import { useTranslation } from 'next-i18next'
import CustomSearchDropdown from './CustomSearchDropdown'

export default function SearchFields() {
    const { baths, setBaths, searchCriteria } = useSearchContext()
    const { t } = useTranslation('search')

    return (
        <CustomSearchDropdown
            Icon={BathroomIcon}
            option={baths}
            setOption={setBaths}
            title={t('baths')}
            placeholder={t('baths')}
            name="bath"
            dropDownLimit={searchCriteria.baths || 8}
            minWidth="110px"
        />
    )
}

import { useSettingContext } from '@/contexts/settings'
import { getLocaleStartOfWeek } from '@/lib/localeUtils'
import { getStartOfMonth, hideScrollBar, isStartOfMonth } from '@/lib/utils'
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Grid,
  GridItem,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'
import { add } from 'date-fns'
import React, { useEffect } from 'react'
import { DayPicker } from 'react-day-picker'

/**
 *
 * @param {DateRange} selected
 * @param {function} onSelect
 * @param {string} locale
 * @return {JSX.Element[]}
 */
const InternalGrid = ({ selected, onSelect, locale }) => {
  const { settings } = useSettingContext()
  const startOfMonth = getStartOfMonth()
  const startDayOfWeek = getLocaleStartOfWeek(locale)
  const popupCalendarMaxMonths = +settings.popupCalendarMaxMonths
  const startDate = add(new Date(), { days: +settings.earliestCheckin })
  const calendarMonthsCount = isStartOfMonth()
    ? popupCalendarMaxMonths
    : popupCalendarMaxMonths + 1
  const endDateUsingMaxMonthsSetting = add(new Date(), {
    months: popupCalendarMaxMonths
  })

  return Array(calendarMonthsCount)
    .fill(0)
    .map((_, i) => {
      const currentDate = startOfMonth.add(i, 'month')
      const isLastMonthInCalendar = i + 1 === calendarMonthsCount
      const isFirstMonthInCalendar = i === 0
      const fromDate = isFirstMonthInCalendar ? startDate : null
      const toDate = isLastMonthInCalendar ? endDateUsingMaxMonthsSetting : null

      return (
        <GridItem
          key={i}
          className="grid-item"
          sx={{
            paddingX: 2
          }}
        >
          <VStack spacing={4}>
            <Text sx={{ fontWeight: 'bold', paddingTop: 4 }} as="span">
              {currentDate.format('MMMM YYYY')}
            </Text>
            <DayPicker
              mode="range"
              weekStartsOn={startDayOfWeek}
              fromDate={fromDate}
              toDate={toDate}
              selected={selected}
              onSelect={onSelect}
              defaultMonth={currentDate.toDate()}
              disableNavigation
              formatters={{ formatCaption: () => null }}
              style={{ paddingBottom: 10 }}
            />
          </VStack>
        </GridItem>
      )
    })
}

/**
 *
 * @param {boolean} isOpen
 * @param {function} setIsOpen
 * @param {object} dates
 * @param {function} onSelect
 * @param {function} resetDates
 * @param {function} t
 * @param {string} locale
 * @return {JSX.Element[]}
 */
export const FullScreenCalendar = ({
  isOpen = false,
  setIsOpen,
  dates,
  onSelect,
  resetDates,
  t,
  locale
}) => {
  const templateColumns = {
    base: 'repeat(1, 1fr)',
    lg: 'repeat(2, 1fr)',
    xl: 'repeat(3, 1fr)'
  }

  useEffect(() => {
    //disable scroll and hide header on wordpress version when full calendar is open
    const header = document.getElementById('header')

    if (isOpen) {
      hideScrollBar(true)
      if (header) {
        header.style.display = 'none'
      }
    } else {
      hideScrollBar(false)
      if (header) {
        header.style.display = 'block'
      }
    }
  }, [isOpen])

  if (!isOpen) return null
  return (
    <Box
      zIndex={20}
      position={'fixed'}
      top="0"
      bottom="0"
      left="0"
      right="0"
      background={'white'}
      width="full"
      overflowY={'scroll'}
    >
      <VStack divider={<StackDivider />}>
        <Box
          display={'flex'}
          justifyContent="space-between"
          alignItems="center"
          w="full"
        >
          <Flex py={3}>
            <Button
              size="xs"
              fontWeight="normal"
              textDecoration="underline"
              variant="ghost"
              onClick={resetDates}
            >
              {t('clear-dates')}
            </Button>
          </Flex>
          <CloseButton onClick={() => setIsOpen(false)} />
        </Box>
        <Grid templateColumns={templateColumns} gap={4} w="100%" py="4">
          <InternalGrid locale={locale} selected={dates} onSelect={onSelect} />
        </Grid>
      </VStack>
    </Box>
  )
}

import { useSearchContext } from '@/contexts/search'
import { CloseButton, HStack, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

export default function SearchSummaryHeader() {
  const { setCompactMode, isMobile, isTablet } = useSearchContext()
  const { t } = useTranslation('search')

  return (
    <HStack
      px="0"
      pt={1}
      pb="3"
      spacing={0}
      justifyContent="space-between"
      w="100%"
      alignItems="center"
    >
      <Text fontSize={'m'} fontWeight={'bold'} mb={1}>
        {t('search-properties')}
      </Text>
      <CloseButton
        color="gray.500"
        size="md"
        onClick={() => setCompactMode({ tablet: isTablet, mobile: isMobile })}
      />
    </HStack>
  )
}

import IconTriggerButton from '@/components/core/IconTriggerButton'
import IncrementControl from '@/components/reservations/create-reservation/IncrementControl'
import { usePageContext } from '@/contexts/page'
import { useSearchContext } from '@/contexts/search'
import ArrowDown from '@/icons/arrow-down.svg'
import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  VStack
} from '@chakra-ui/react'
import GuestsIcon from 'icons/guest.svg'
import { capitalize } from 'lodash'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

export default function Guests() {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const { t } = useTranslation('search')
  const { searchCriteria } = usePageContext()
  const { guests, setGuests, setSearchBarOverlay, isMobile } =
    useSearchContext()
  const incrementControlStyles = { width: '100%', px: '20px', py: '24px' }

  const popOverContentStyles = {
    border: '1px solid',
    borderColor: 'gray.300',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px'
  }

  return (
    <Popover
      onOpen={() => {
        onOpen()
        setSearchBarOverlay(true)
      }}
      onClose={() => {
        onClose()
        setSearchBarOverlay(false)
      }}
      isOpen={isOpen}
      placement="bottom"
      variant="responsive"
      trigger="click"
      matchWidth={true}
    >
      {isMobile ? (
        <MobileTrigger isOpen={isOpen} t={t} guests={guests} />
      ) : (
        <DesktopTabletTrigger isOpen={isOpen} t={t} />
      )}
      <PopoverContent sx={popOverContentStyles}>
        <VStack divider={<StackDivider />}>
          <IncrementControl
            border="none"
            name="adultsCount"
            label={capitalize(t('adults'))}
            onChange={(e) => setGuests({ ...guests, adults: e })}
            value={+guests.adults}
            minimumValue={1}
            maximumValue={searchCriteria?.max_occupancy - guests.children}
            {...incrementControlStyles}
          />
          <IncrementControl 
            disabled={guests.adults===searchCriteria?.max_occupancy}
            name="childrenCount"
            label={capitalize(t('children'))}
            onChange={(e) => setGuests({ ...guests, children: e })}
            value={+guests.children}
            minimumValue={0}
            maximumValue={searchCriteria?.max_occupancy - guests.adults}
            {...incrementControlStyles}
          />
          {searchCriteria?.pet_occupancy_supported && (
            <IncrementControl
              name="petsCount"
              label={capitalize(t('pets'))}
              onChange={(e) => setGuests({ ...guests, pets: e })}
              value={+guests.pets}
              minimumValue={0}
              {...incrementControlStyles}
            />
          )}

          {isMobile && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              p="2"
              w="full"
            >
              <Button
                className="btn-primary"
                colorScheme="wpHeaderScheme"
                onClick={() => setIsOpen(false)}
              >
                {t('continue')}
              </Button>
            </Box>
          )}
        </VStack>
      </PopoverContent>
    </Popover>
  )
}

const DesktopTabletTrigger = ({ isOpen, t }) => {
  const { guests } = useSearchContext()

  const guestCount =
    parseInt(guests.adults) + parseInt(guests.children) + parseInt(guests.pets)
  const content = t('guest', { count: guestCount })
  const titleSize = !content ? 'md' : 'xs'

  return (
    <PopoverTrigger>
      <IconTriggerButton
        flex="1"
        minWidth="120px"
        borderColor={isOpen ? 'wpMain.primary' : 'wpMain.border'}
        borderLeftWidth={isOpen ? '1px' : 0}
        borderRadius="none"
        backgroundColor="white"
        h="48px"
        variant="outline"
        title={t('guests')}
        content={content}
        padding={3}
        titleSize={titleSize}
        LeftIcon={GuestsIcon}
        colors={{
          icon: 'gray.700',
          title: 'gray.700',
          content: 'gray.700'
        }}
        justifyContent="flex-start"
      />
    </PopoverTrigger>
  )
}

const MobileTrigger = ({ isOpen, t, guests }) => {
  return (
    <PopoverTrigger>
      <Button
        w="full"
        marginBottom={'8px'}
        variant="outline"
        backgroundColor="white"
        color="gray.800"
        fontWeight={'normal'}
        borderColor={isOpen ? 'wpMain.primary' : 'wpMain.border'}
        rightIcon={<Icon as={ArrowDown} fill="gray.300" />}
        justifyContent="space-between"
      >
        {parseInt(guests.adults) +
          parseInt(guests.children) +
          parseInt(guests.pets)}{' '}
        {t('guests')}
      </Button>
    </PopoverTrigger>
  )
}

import React from 'react'
import { Button, Flex, Icon, Text } from '@chakra-ui/react'
import SearchIcon from 'icons/search.svg'
import { useTranslation } from 'next-i18next'
import Dates from './Dates'
import Guests from './Guests'
import Beds from './Beds'
import Baths from './Baths'
import States from './States'
import { useSettingContext } from '@/contexts/settings'

export default function SearchFields({ onSearch }) {
  const { settings } = useSettingContext()  
  const { t } = useTranslation('search')

  const componentsMap = {
    dates: Dates,
    beds: Beds,
    baths: Baths,
    sleeps: Guests,
    states: States
  }
  
  let searchOptionsArray = []
  if (settings && settings.searchOptions){
    const tempArray = settings.searchOptions.split(",").map(function(item) {
      if (item) {
        return item.trim()
      } else {
        return ""
      }
    })
    searchOptionsArray = tempArray.filter(item => item !== "")
  }

  let componentsList = ['dates', 'sleeps', 'beds', 'baths'] // default search options
  if (Array.isArray(searchOptionsArray) && searchOptionsArray.length > 0) {
    componentsList = searchOptionsArray
  }

  return (
    <Flex
      alignItems={'top'}
      pt={{ base: 0, lg: '4' }}
      px="0"
      spacing={0}
      w="full"
      flexDir={{ base: 'column', md: 'row' }}
    >
      {componentsList.map((str, index) => {
        if (str in componentsMap) {
          const Component = componentsMap[str]
          return <Component key={index} />
        }
        return null 
      })}
      <Button
        onClick={onSearch}
        w={{ base: 'full', md: 'auto' }}
        mt={{ base: 2, md: 0 }}
        h={{ base: '40px', md: '48px' }}
        px={{ base: 4, md: 7 }}
        fontSize="sm"
        borderTopLeftRadius={{ base: 'md', md: '0' }}
        borderBottomLeftRadius={{ base: 'md', md: '0' }}
        colorScheme="wpHeaderScheme"
      >
        <Icon as={SearchIcon} fill="white" />
        <Text ml="2" color="white">
          {t('Search')}
        </Text>
      </Button>
    </Flex>
  )
}

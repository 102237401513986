import sortOptions from '@/constants/property-sort-options'
import ArrowDown from '@/icons/arrow-down.svg'
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react'

export default function SortDropdown({
  sortKey = 'random',
  onChange,
  ...props
}) {
  const selected =
    sortOptions.find((so) => so.value === sortKey) ?? sortOptions[0]

  return (
    <Box>
      <Menu isLazy>
        <MenuButton
          as={Button}
          fontSize="sm"
          fontWeight="normal"
          rightIcon={<Icon as={ArrowDown} fill="gray.500" />}
          {...props}
        >
          <Box as="span">Sort: {selected.label}</Box>
        </MenuButton>
        <MenuList zIndex={3}>
          <MenuOptionGroup defaultValue={sortKey} type="radio">
            {sortOptions.map((so) => (
              <MenuItemOption
                key={so.value}
                value={so.value}
                onClick={() => onChange(so.value)}
              >
                {so.label}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          {/* {sortOptions.map((so) => (
            <MenuItem
              isChecked={so.value === 'price-lh'}
              key={so.value}
              value={so.value}
              onClick={() => onChange(so.value)}
            >
              <Text fontSize="sm">{so.label}</Text>
            </MenuItem>
          ))} */}
        </MenuList>
      </Menu>
    </Box>
  )
}

import { useSearchContext } from '@/contexts/search'
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  Spacer,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import PenIcon from 'icons/pen.svg'
import SearchIcon from 'icons/search.svg'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import SortDropdown from '../SortDropdown'
import Filters from './Filters'
import SearchFields from './SearchFields'
import SearchSummaryHeader from './SearchSummaryHeader'
import SelectPropertyDropdown from './SelectPropertyDropDown'

const TabletSearchBar = ({ stickyProps, ...props }) => {
  const { compactMode, isTablet } = useSearchContext()

  return (
    <Box w="full" className="tablet-searchbar">
      <CompactSearchBar
        isTablet={isTablet}
        showHideBarsCss={
          !compactMode.tablet ? { height: 0, overflow: 'hidden' } : {}
        }
        stickyProps={stickyProps}
        {...props}
      />
      <FullSearchBar
        isTablet={isTablet}
        showHideBarsCss={
          compactMode.tablet ? { height: 0, overflow: 'hidden' } : {}
        }
        stickyProps={stickyProps}
        {...props}
      />
    </Box>
  )
}

const FullSearchBar = ({
  onSearchHandler,
  allProperties,
  isOpen,
  onOpen,
  onClose,
  stickyProps,
  showHideBarsCss,
  isTablet
}) => {
  const { onSortChanged, sortKey } = useSearchContext()

  return (
    <Box
      background={'gray.100'}
      boxShadow="md"
      id="tablet-full-searchbar"
      {...showHideBarsCss}
      {...stickyProps}
    >
      <Container
        maxW="1450px"
        display="flex"
        justifyContent={'space-between'}
        alignItems="start"
        p="3"
      >
        <Flex w="full" flexDirection="column">
          <Flex
            w="full"
            flexDir={'column'}
            justifyContent="space-between"
            alignItems={'flex-start'}
            spacing={0}
          >
            <SearchSummaryHeader />
            <SearchFields onSearch={onSearchHandler} />
            <SelectPropertyDropdown properties={allProperties} />
          </Flex>
          <Divider my="2" orientation="horizontal" />
          <HStack
            pb={1.5}
            minH={10}
            justifyContent={'space-between'}
            display="flex"
          >
            {isTablet && (
              <Filters isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            )}
            <SortDropdown sortKey={sortKey} onChange={onSortChanged} />
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}

const CompactSearchBar = ({
  isOpen,
  onOpen,
  onClose,
  stickyProps,
  showHideBarsCss,
  isTablet
}) => {
  const {
    bedrooms,
    baths,
    guests,
    dates,
    setCompactMode,
    onSortChanged,
    sortKey
  } = useSearchContext()
  const { t } = useTranslation('search')

  const textValues = useMemo(() => {
    return {
      bedrooms: bedrooms ? bedrooms.label : `${'any'} ${t('bedrooms')}`,
      guestsTotal: Object.values(guests).reduce(
        (prev, next) => parseInt(prev) + parseInt(next)
      ),
      dates:
        dates.from && dates.to
          ? `${format(dates.from, 'MMM dd, yyyy')} - ${format(
              dates.to,
              'MMM dd, yyyy'
            )}`
          : t('search-property-availability'),
      baths: baths ? baths.label : `${'any'} ${t('baths')}`
    }
  }, [dates, bedrooms, guests, baths, t])

  return (
    <Box
      background={'gray.100'}
      boxShadow="md"
      id="tablet-compact-searchbar"
      {...showHideBarsCss}
      {...stickyProps}
    >
      <Container
        maxW="1450px"
        display="flex"
        justifyContent={'space-between'}
        alignItems="start"
        p="3"
      >
        <Flex
          w="full"
          px="4"
          justifyContent={'space-between'}
          alignItems="center"
          bgColor={'gray.100'}
          borderRadius="md"
        >
          <HStack
            p={{ base: 2, lg: 10 }}
            onClick={() => setCompactMode({ tablet: false })}
            _hover={{ cursor: 'pointer' }}
          >
            <Icon
              as={SearchIcon}
              w={30}
              height={30}
              mr={'20px'}
              fill="gray.800"
            />
            <VStack alignItems={'flex-start'}>
              <Text fontWeight={'bold'}>
                {textValues.dates}{' '}
                <Icon
                  as={PenIcon}
                  w={4}
                  height={4}
                  ml={'10px'}
                  fill="gray.800"
                />
              </Text>
              <HStack divider={<StackDivider />} flexWrap="wrap">
                <Text>{textValues.bedrooms}</Text>
                <Text>
                  {t('guest', {
                    count: textValues.guestsTotal
                  })}
                </Text>
                <Text>{textValues.baths}</Text>
              </HStack>
            </VStack>
          </HStack>
          <HStack divider={<StackDivider />} h="48px" p={{ base: 2, lg: 10 }}>
            <Spacer />
            {isTablet && (
              <Filters isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            )}
            <SortDropdown sortKey={sortKey} onChange={onSortChanged} />
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}

export default TabletSearchBar

import IconTriggerButton from '@/components/core/IconTriggerButton'
import { useSearchContext } from '@/contexts/search'
import ArrowDown from '@/icons/arrow-down.svg'
import { Box, Icon, useToken } from '@chakra-ui/react'
import { useState } from 'react'
import ReactSelect from 'react-select'

const selectStyles = {
  control: () => ({
    display: 'none'
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' })
}

export default function CustomSearchDropdown({
  Icon,
  option,
  setOption,
  title,
  placeholder,
  dropDownLimit,
  dropDownOptions,
  name,
  minWidth
}) {
  const { isMobile } = useSearchContext()

  let options = Array()
  if (dropDownOptions) {
    options = dropDownOptions.map((option) => ({
      value: option.value,
      label: option.label
    }))
  }else{
    options = Array.from({ length: dropDownLimit }, (_, i) => i + 1).map(
      (index) => {
        return { value: index, label: `${index}+ ${name}${index > 1 ? 's' : ''}` }
      }
    )
 }
 options.unshift({ value: 0, label: 'Any' })
 

  if (isMobile) {
    return (
      <MobileSearchDropdown
        options={options}
        option={option}
        setOption={setOption}
        placeholder={placeholder}
      />
    )
  }

  return (
    <DesktopTabletSearchDropdown
      options={options}
      Icon={Icon}
      option={option}
      setOption={setOption}
      title={title}
      minWidth={minWidth}
    />
  )
}

const DesktopTabletSearchDropdown = ({
  options,
  Icon,
  option,
  setOption,
  title,
  minWidth
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { setSearchBarOverlay, searchBarOverlay } = useSearchContext()

  const toggleOpen = () => {
    setIsOpen(!isOpen)
    setSearchBarOverlay(!searchBarOverlay)
  }

  const onSelectChange = (item) => {
    toggleOpen()
    setOption(item)
  }
  const content = option ? option.label : null
  const titleSize = !content ? 'sm' : 'xs'

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <IconTriggerButton
          width="full"
          minWidth={minWidth}
          borderColor={isOpen ? 'wpMain.primary' : 'wpMain.border'}
          borderLeftWidth={isOpen ? '1px' : 0}
          borderRadius="none"
          backgroundColor="white"
          h="48px"
          variant="outline"
          onClick={toggleOpen}
          titleSize={titleSize}
          padding={3}
          title={title}
          content={content}
          LeftIcon={Icon}
          colors={{
            icon: 'gray.500',
            title: 'gray.800',
            content: 'gray.800'
          }}
          justifyContent="flex-start"
        />
      }
    >
      <ReactSelect
        instanceId={`${option?.label}-selector`}
        isSearchable={false}
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        options={options}
        styles={selectStyles}
        tabSelectsValue={false}
        value={option}
      />
    </Dropdown>
  )
}

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)'
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 3,
        width: '100%'
      }}
      {...props}
    />
  )
}
const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
)
const DropdownIndicator = () => {
  return (
    <Box pr="4">
      <Icon as={ArrowDown} fill="gray.300" />
    </Box>
  )
}
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: 'relative', flex: 1 }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

const MobileSearchDropdown = ({ options, option, setOption, placeholder }) => {
  const { setSearchBarOverlay, searchBarOverlay } = useSearchContext()
  const [menuOpen, setMenuOpen] = useState(false)

  const [primaryColor, borderColor] = useToken('colors.wpMain', [
    'primary',
    'border'
  ])
  const [gray800] = useToken('colors.gray', ['800'])

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: menuOpen ? primaryColor : borderColor,
      boxShadow: `none`,
      '&:focus': {
        borderColor: primaryColor
      },
      '&:hover': {
        borderColor: primaryColor
      },
      outline: 'none',
      marginBottom: '8px'
    }),
    singleValue: (provided, { selectProps: { color } }) => ({
      ...provided,
      color
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3
    }),
    placeholder: (provided, { selectProps: { color } }) => ({
      ...provided,
      color
    })
  }

  const onSelectChange = (item) => {
    setSearchBarOverlay(!searchBarOverlay)
    setOption(item)
  }

  return (
    <ReactSelect
      placeholder={placeholder}
      instanceId={`${option?.label}-selector`}
      color={gray800}
      onMenuOpen={() => {
        setSearchBarOverlay(true)
        setMenuOpen(true)
      }}
      onMenuClose={() => {
        setSearchBarOverlay(false)
        setMenuOpen(false)
      }}
      isSearchable={false}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: DropdownIndicator
      }}
      onChange={onSelectChange}
      options={options}
      value={option}
      styles={customStyles}
    />
  )
}

import { useSearchContext } from '@/contexts/search'
import useStickySearchBar from '@/hooks/useStickySearchBar'
import { useDisclosure } from '@chakra-ui/react'
import React from 'react'
import DesktopSearchBar from './DesktopSearchBar'
import MobileSearchBar from './MobileSearchBar'
import TabletSearchBar from './TabletSearchBar'

export default function SearchBar({ allProperties }) {
  const { isMobile, isTablet, onSearch, setCompactMode } = useSearchContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { fullRef, compactRef, stickyProps } = useStickySearchBar()

  const onSearchHandler = React.useCallback(() => {
    onSearch()
    setCompactMode({ tablet: isTablet, mobile: isMobile })
  }, [onSearch, isTablet, isMobile, setCompactMode])

  return (
    <div w="full" id="search-bar">
      <DesktopSearchBar
        onSearchHandler={onSearchHandler}
        allProperties={allProperties}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        fullRef={fullRef}
        compactRef={compactRef}
        stickyProps={stickyProps}
      />

      <TabletSearchBar
        allProperties={allProperties}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onSearchHandler={onSearchHandler}
        fullRef={fullRef}
        compactRef={compactRef}
        stickyProps={stickyProps}
      />
      <MobileSearchBar
        allProperties={allProperties}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onSearchHandler={onSearchHandler}
        fullRef={fullRef}
        compactRef={compactRef}
        stickyProps={stickyProps}
      />
    </div>
  )
}

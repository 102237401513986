import { useSearchContext } from '@/contexts/search'
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import FiltersIcon from 'icons/filters.svg'
import PenIcon from 'icons/pen.svg'
import SearchIcon from 'icons/search.svg'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import Filters from './Filters'
import SearchFields from './SearchFields'
import SearchSummaryHeader from './SearchSummaryHeader'
import SelectPropertyDropdown from './SelectPropertyDropDown'

const MobileSearchBar = ({ stickyProps, ...props }) => {
  const { compactMode, isMobile } = useSearchContext()

  return (
    <Box w="full" className="mobile-searchbar">
      <CompactSearchBar
        showHideBarsCss={
          !compactMode.mobile ? { height: 0, overflow: 'hidden' } : {}
        }
        stickyProps={stickyProps}
        {...props}
      />
      <FullSearchBar
        showHideBarsCss={
          compactMode.mobile ? { height: 0, overflow: 'hidden' } : {}
        }
        isMobile={isMobile}
        stickyProps={stickyProps}
        {...props}
      />
    </Box>
  )
}

const FullSearchBar = ({
  onSearchHandler,
  allProperties,
  isOpen,
  onOpen,
  onClose,
  stickyProps,
  isMobile,
  showHideBarsCss
}) => {
  return (
    <Box
      background={'gray.100'}
      boxShadow="md"
      id="mobile-full-searchbar"
      {...showHideBarsCss}
      {...stickyProps}
    >
      <Container
        maxW="1450px"
        display="flex"
        justifyContent={'space-between'}
        alignItems="start"
        p="3"
      >
        <Flex w="full" flexDirection="column">
          <Flex
            w="full"
            flexDir={'column'}
            justifyContent="space-between"
            alignItems={'flex-start'}
            spacing={0}
          >
            <SearchSummaryHeader />
            <SearchFields onSearch={onSearchHandler} />
            <SelectPropertyDropdown properties={allProperties} />
          </Flex>
          <Divider my="2" orientation="horizontal" />
          <HStack
            pb={1.5}
            minH={10}
            justifyContent={'space-between'}
            display="flex"
          >
            {isMobile && (
              <Filters isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            )}
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}

const CompactSearchBar = ({ onOpen, stickyProps, showHideBarsCss }) => {
  const { bedrooms, baths, guests, dates, setCompactMode } = useSearchContext()
  const { t } = useTranslation('search')

  const textValues = useMemo(() => {
    return {
      bedrooms: bedrooms ? bedrooms.label : `${'any'} ${t('bedrooms')}`,
      guestsTotal: Object.values(guests).reduce(
        (prev, next) => parseInt(prev) + parseInt(next)
      ),
      dates:
        dates.from && dates.to
          ? `${format(dates.from, 'MMM dd, yyyy')} - ${format(
              dates.to,
              'MMM dd, yyyy'
            )}`
          : t('search-property-availability'),
      baths: baths ? baths.label : `${'any'} ${t('baths')}`
    }
  }, [dates, bedrooms, guests, baths, t])

  return (
    <Box
      background={'white'}
      boxShadow="md"
      id="mobile-compact-searchbar"
      {...showHideBarsCss}
      {...stickyProps}
    >
      <Container
        maxW="1450px"
        display="flex"
        justifyContent={'space-between'}
        alignItems="start"
        p="3"
      >
        <Flex w="full" flexDirection="column">
          <Flex
            w="full"
            flexDir={'column'}
            justifyContent="space-between"
            alignItems={'flex-start'}
            spacing={0}
          >
            <Flex w="full">
              <Box
                _hover={{ cursor: 'pointer' }}
                px="2"
                flexBasis={'85%'}
                bgColor={'gray.100'}
                borderRadius="md"
                onClick={() => setCompactMode({ mobile: false })}
              >
                <HStack p={2}>
                  <Icon
                    as={SearchIcon}
                    w={5}
                    height={5}
                    mr={4}
                    fill="gray.800"
                  />
                  <VStack alignItems={'flex-start'}>
                    <Text fontWeight={'bold'} fontSize="xs">
                      {textValues.dates}{' '}
                      <Icon
                        as={PenIcon}
                        w={4}
                        height={4}
                        ml={'10px'}
                        fill="gray.800"
                      />
                    </Text>
                    <HStack divider={<StackDivider />} flexWrap="wrap">
                      <Text fontSize="xs">{textValues.bedrooms}</Text>
                      <Text fontSize="xs">
                        {t('guest', {
                          count: textValues.guestsTotal
                        })}
                      </Text>
                      <Text fontSize="xs">{textValues.baths}</Text>
                    </HStack>
                  </VStack>
                </HStack>
              </Box>
              <Flex
                flexDir={'column'}
                justifyContent="space-between"
                alignItems="center"
                flexBasis="15%"
              >
                <Box
                  bgColor={'gray.100'}
                  borderRadius="full"
                  boxSize="40px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={onOpen}
                  as="button"
                >
                  <Icon fill="gray.800" as={FiltersIcon} boxSize="15px" />
                </Box>
                <Text color="gray.800" fontSize="12px">
                  {t('filters')}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default MobileSearchBar

import { loadAmenityIcon } from '@/components/property/utils/amenity'
import { useSearchContext } from '@/contexts/search'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  StackDivider,
  Text,
  useCheckboxGroup,
  VStack
} from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useState } from 'react'

const Filter = ({
  option,
  getCheckboxProps,
  checkedValues,
  filterKey,
  onChange,
  showAmenityIcons
}) => {
  return (
    <Checkbox
      {...getCheckboxProps({ value: option.id })}
      key={option.id}
      sx={{
        '.chakra-checkbox__label': {
          fontSize: 'sm'
        }
      }}
      value={option.id}
      isChecked={
        option.featured
          ? option.featured
          : checkedValues.includes(String(option.id))
      }
      onChange={(e) => {
        onChange(e.target.value, filterKey)
      }}
    >
      <Box alignItems={'center'} display="flex" gap="2">
        {showAmenityIcons && (
          <Icon boxSize={5} as={loadAmenityIcon(option.icon)} fill="#a8a8a8" />
        )}
        {option.name}
      </Box>
    </Checkbox>
  )
}

const FilterCategory = ({
  filter,
  filterKey,
  getCheckboxProps,
  checkedValues,
  onChange
}) => {
  const showAmenityIcons = useMemo(() => {
    if (filterKey !== 'amenity') {
      return false
    }
    return filter.options.some((option) => option.icon && option.icon !== '')
  }, [filterKey, filter])

  return (
    <Box key={filter.key} w="full" pb="7">
      <Text fontSize="xl" mb="3">
        {filter.title}
      </Text>
      <SimpleGrid columns={2} spacingX={10} spacingY={1.5}>
        {filter.options.map((option) => {
          return (
            <Box key={option.id}>
              <Filter
                showAmenityIcons={showAmenityIcons}
                option={option}
                getCheckboxProps={getCheckboxProps}
                filterKey={filterKey}
                onChange={onChange}
                checkedValues={checkedValues}
              />
            </Box>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}

export default function Filters({ isOpen, onOpen, onClose }) {
  const { t, i18n } = useTranslation('search')
  const {
    searchCriteria,
    onSearch,
    categories,
    setCategories,
    customgroups,
    setCustomgroups,
    isMobile,
    compactMode
  } = useSearchContext()
  const [internalFilters, setInternalFilters] = useState({
    categories,
    customgroups
  })

  const { value, getCheckboxProps, onChange, setValue } = useCheckboxGroup({
    value: [...categories, ...customgroups]
  })

  useEffect(() => {
    setInternalFilters({
      categories,
      customgroups
    })
  }, [categories, customgroups])

  const handleOnChange = (val, filterKey) => {
    onChange(val)
    let newFilters = internalFilters

    const filterKeyExistInCategories = searchCriteria?.categories.find(
      (cat) => cat.category_type === filterKey
    )

    if (filterKeyExistInCategories) {
      newFilters = {
        ...internalFilters,
        categories:
          internalFilters.categories.indexOf(val) === -1
            ? [...internalFilters.categories, val]
            : internalFilters.categories.filter((v) => v !== val)
      }
    } else {
      newFilters = {
        ...internalFilters,
        customgroups:
          internalFilters.customgroups.indexOf(val) === -1
            ? [...internalFilters.customgroups, val]
            : internalFilters.customgroups.filter((v) => v !== val)
      }
    }
    setCategories(newFilters.categories)
    setCustomgroups(newFilters.customgroups)
  }

  const mobileCompactMode = isMobile && compactMode.mobile

  const filters = useMemo(() => {
    const filters = {}

    if (
      searchCriteria?.categories?.length === 0 &&
      searchCriteria?.customgroups?.length === 0
    ) {
      return null
    }

    searchCriteria.categories?.forEach((category) => {
      if (filters[category.category_type]) {
        filters[category.category_type].options.push({
          id: category.id,
          name: category.display_text || category.category_name,
          icon: category.image_name
        })
      } else {
        filters[category.category_type] = {
          title: i18n.exists(`search:filter-types.${category.category_type}`)
            ? t(`filter-types.${category.category_type}`)
            : capitalize(category.category_type),
          options: [
            {
              id: category.id,
              name: category.display_text || category.category_name,
              icon: category.image_name
            }
          ]
        }
      }
    })

    searchCriteria.customgroups?.forEach((customgroup) => {
      const customgroupType =
        customgroup.menu_header !== ''
          ? customgroup.menu_header.toLowerCase().split(' ').join('_')
          : 'customgroups'
      if (filters[customgroupType]) {
        filters[customgroupType].options.push({
          id: customgroup.id,
          name: customgroup.customgroup_name,
          icon: null
        })
      } else {
        filters[customgroupType] = {
          title:
            customgroup.menu_header !== ''
              ? customgroup.menu_header
              : 'Custom Groups',
          options: [
            {
              id: customgroup.id,
              name: customgroup.customgroup_name,
              icon: null
            }
          ]
        }
      }
    })
    return filters
  }, [searchCriteria.categories, searchCriteria.customgroups, i18n, t])

  const applyfilters = () => {
    onSearch()
    onClose()
  }

  const filtersBtnText = useMemo(() => {
    let text = ''
    if (filters) {
      text = t('filters')
      if (value.length > 0) {
        text += ` (${value.length})`
      }
    } else {
      text = t('filtering-unavailable')
    }
    return text
  }, [filters, t, value])

  return (
    <>
      {!mobileCompactMode && (
        <Button
          variant={'outline'}
          bg="white"
          onClick={onOpen}
          fontWeight="normal"
          disabled={!filters}
        >
          {filtersBtnText}
        </Button>
      )}
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('filters')}</ModalHeader>
          <ModalCloseButton zIndex={999} />
          <Divider />
          <ModalBody py="10" px="8">
            {filters && (
              <VStack divider={<StackDivider />}>
                {Object.keys(filters).map((filterKey) => {
                  return (
                    <FilterCategory
                      filter={filters[filterKey]}
                      key={filterKey}
                      filterKey={filterKey}
                      getCheckboxProps={getCheckboxProps}
                      checkedValues={value}
                      onChange={handleOnChange}
                    />
                  )
                })}
              </VStack>
            )}
          </ModalBody>
          <Divider />

          <ModalFooter justifyContent={'space-between'}>
            <Button
              variant="ghost"
              textDecoration="underline"
              onClick={() => {
                setValue([])
                setInternalFilters({
                  categories: [],
                  customgroups: []
                })
              }}
            >
              {t('clear-all')}
            </Button>
            <Button
              className="btn-primary"
              colorScheme="wpHeaderScheme"
              onClick={applyfilters}
            >
              {t('apply-filters')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

import IconTriggerButton from '@/components/core/IconTriggerButton'
import { useSearchContext } from '@/contexts/search'
import { useSignalContext } from '@/contexts/signal'
import { formatDate } from '@/lib/localeUtils'
import { Box, Button, ButtonGroup, Icon } from '@chakra-ui/react'
import { format } from 'date-fns'
import BathroomIcon from 'icons/bathroom.svg'
import BedroomIcon from 'icons/bed.svg'
import CalendarIcon from 'icons/calendar.svg'
import GuestsIcon from 'icons/guest.svg'
import SearchIcon from 'icons/search.svg'
import StatesIcon from 'icons/location.svg'
import { useTranslation } from 'next-i18next'
import { useSettingContext } from '@/contexts/settings'

const Overlay = () => {
  const { setCompactMode } = useSearchContext()

  return (
    <Box
      w="full"
      h="full"
      position="absolute"
      zIndex="2"
      borderRadius="5px"
      cursor="pointer"
      onClick={() => setCompactMode({ desktop: false })}
    />
  )
}

const CompactSearchFields = () => {
  const { bedrooms, baths, guests, dates, states } = useSearchContext()
  const { locale } = useSignalContext()
  const { t } = useTranslation('search')
  const { settings } = useSettingContext()

  const getDateContent = (date, locale) => {
    return date ? formatDate(format(date, 'yyyy-MM-dd'), locale, 'MMM d') : ''
  }

  const Checkin = {
    title: dates.from ? t('checkin') : null,
    content: dates.from ? getDateContent(dates.from, locale) : t('checkin'),
    icon: CalendarIcon,
    minWidth: '120px'
  }

  const Checkout = {
    title: dates.to ? t('checkout') : null,
    content: dates.to ? getDateContent(dates.to, locale) : t('checkout'),
    icon: CalendarIcon,
    minWidth: '120px'
  }

  const States = {
    title: null,
    content: states.value !== 0 ? states.label : 'Any',
    icon: StatesIcon,
    minWidth: '120px'
  }

  const Guests = {
    title: null,
    content:
      parseInt(guests.adults) +
      parseInt(guests.children) +
      parseInt(guests.pets),
    icon: GuestsIcon,
    minWidth: '80px'
  }

  const Beds = {
    title: null,
    content: bedrooms.value !== 0 ? bedrooms.value + ' +' : 'Any',
    icon: BedroomIcon,
    minWidth: '80px'
  }

  const Baths = {
    title: null,
    content: baths.value !== 0 ? baths.value + ' +' : 'Any',
    icon: BathroomIcon,
    minWidth: '80px'
  }

  const navMap = {
    dates: Checkin,
    beds: Beds,
    baths: Baths,
    sleeps: Guests,
    states: States
  }

  let searchOptionsArray = []
  if (settings && settings.searchOptions) {
    const tempArray = settings.searchOptions.split(",").map(function (item) {
      if (item) {
        return item.trim()
      } else {
        return ""
      }
    })
    searchOptionsArray = tempArray.filter(item => item !== "")
  }

  let navList = ['dates', 'sleeps', 'beds', 'baths'] // default search options
  if (Array.isArray(searchOptionsArray) && searchOptionsArray.length > 0) {
    navList = searchOptionsArray
  }

  const fields = []
  navList.map(str => {
    if (str in navMap) {
      if (str == 'dates') {
        fields.push(Checkin, Checkout)
      } else
        fields.push(navMap[str])
    }
  })
  return (
    <Box position="relative">
      <Overlay />
      <ButtonGroup
        isAttached
        borderLeftWidth={'1px'}
        borderRadius="5px"
        borderColor={'wpMain.border'}
      >
        {fields.map((field, key) => {
          return (
            <IconTriggerButton
              key={key}
              width="full"
              minWidth={field.minWidth}
              borderColor={'wpMain.border'}
              borderRadius="none"
              borderTopLeftRadius={key === 0 ? '5px' : ''}
              borderBottomLeftRadius={key === 0 ? '5px' : ''}
              borderLeftWidth={0}
              title={field.title}
              content={field.content}
              LeftIcon={field.icon}
              backgroundColor="white"
              h="48px"
              variant="outline"
              titleSize="9px"
              padding={3}
              colors={{
                icon: 'gray.500',
                title: 'gray.800',
                content: 'gray.800'
              }}
              justifyContent="flex-start"
            />
          )
        })}
        <Button
          h={'48px'}
          px={7}
          fontSize="sm"
          colorScheme="wpHeaderScheme"
          borderTopLeftRadius={0}
          borderBottomLeftRadius={0}
        >
          <Icon as={SearchIcon} fill="white" />
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default CompactSearchFields

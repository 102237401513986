import { useSearchContext } from '@/contexts/search'
import {
  Box,
  Container,
  Divider,
  Flex,
  HStack,
  Spacer,
  StackDivider
} from '@chakra-ui/react'
import SortDropdown from '../SortDropdown'
import CompactSearchFields from './CompactSearchFields'
import Filters from './Filters'
import SearchFields from './SearchFields'
import SelectPropertyDropdown from './SelectPropertyDropDown'

const DesktopSearchBar = ({ stickyProps, ...props }) => {
  const { compactMode } = useSearchContext()

  return (
    <Box w="full" className="desktop-searchbar">
      <CompactSearchBar
        compactMode={compactMode}
        showHideBarsCss={
          !compactMode.desktop ? { height: 0, overflow: 'hidden' } : {}
        }
        stickyProps={stickyProps}
        {...props}
      />
      <FullSearchBar
        compactMode={compactMode}
        showHideBarsCss={
          compactMode.desktop ? { height: 0, overflow: 'hidden' } : {}
        }
        stickyProps={stickyProps}
        {...props}
      />
    </Box>
  )
}

const FullSearchBar = ({
  onSearchHandler,
  allProperties,
  isOpen,
  onOpen,
  onClose,
  stickyProps,
  showHideBarsCss,
  compactMode
}) => {
  const { sortKey, onSortChanged } = useSearchContext()

  return (
    <Box
      background={{
        md: 'gray.100'
      }}
      boxShadow="md"
      id="desktop-full-searchbar"
      {...showHideBarsCss}
      {...stickyProps}
    >
      <Container
        maxW="1450px"
        display="flex"
        justifyContent={'space-between'}
        alignItems="start"
        p="3"
      >
        <Flex w="full" flexDirection="column">
          <Flex
            w="full"
            flexDir={'row'}
            justifyContent="space-between"
            alignItems={'flex-start'}
            spacing={0}
          >
            <SearchFields onSearch={onSearchHandler} />
            <SelectPropertyDropdown properties={allProperties} />
          </Flex>
          <Divider my="2" orientation="horizontal" />
          <HStack
            pb={1.5}
            minH={10}
            justifyContent={'space-between'}
            display="flex"
          >
            {!compactMode.desktop && (
              <Filters isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            )}
            <SortDropdown sortKey={sortKey} onChange={onSortChanged} />
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}

const CompactSearchBar = ({
  isOpen,
  onOpen,
  onClose,
  stickyProps,
  showHideBarsCss,
  compactMode
}) => {
  const { sortKey, onSortChanged } = useSearchContext()

  return (
    <Box
      background={{
        md: 'gray.100'
      }}
      boxShadow="md"
      id="desktop-compact-searchbar"
      {...showHideBarsCss}
      {...stickyProps}
    >
      <Container
        maxW="1450px"
        display="flex"
        justifyContent={'space-between'}
        alignItems="start"
        p="3"
      >
        <Flex w="full" spacing={0} justifyContent="space-between" py="2">
          <CompactSearchFields />
          <HStack divider={<StackDivider />} h="48px">
            <Spacer />
            {compactMode.desktop && (
              <Filters isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
            )}
            <SortDropdown sortKey={sortKey} onChange={onSortChanged} />
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}

export default DesktopSearchBar

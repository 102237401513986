import useSignalPath from '@/hooks/useSignalPath'
import { hideScrollBar, selectDateRange } from '@/lib/utils'
import { useBreakpointValue } from '@chakra-ui/react'
import { addDays, format, parse } from 'date-fns'
import omitBy from 'lodash/omitBy'
import Router, { useRouter } from 'next/router'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react'
import { useIsomorphicLayoutEffect, usePromise } from 'react-use'

/**
 *
 * @param {Date | undefined}startDate
 * @param {Date | undefined} endDate
 * @return {{from: Date | undefined, to: Date | null}}
 */
const getStartDates = (startDate, endDate) => {
  if (!startDate || !endDate)
    return {
      from: undefined,
      to: null
    }
  return {
    from: startDate,
    to: endDate
  }
}

const SearchContext = createContext(undefined)

function paramsReducer(state, action) {
  switch (action.type) {
    case 'RESET_DATES':
      return { ...state, checkin: undefined, checkout: null }
    case 'RESET_SEARCH':
      return {
        ...state,
        checkin: undefined,
        checkout: null,
        guests: { adults: 1, children: 0, pets: 0 },
        bedrooms: { label: "Any", value: 0 },
        baths: { label: "Any", value: 0 },
        states: { label: "Any", value: '' },
      }
    case 'SET_DATES': {
      const dates = action.payload
      return { ...state, checkin: dates.from, checkout: dates.to }
    }
    case 'SET_BEDROOMS':
      return { ...state, bedrooms: action.payload }
    case 'SET_BATHS':
      return { ...state, baths: action.payload }
    case 'SET_STATES':
      return { ...state, states: action.payload }
    case 'SET_GUESTS':
      return { ...state, guests: action.payload }
    case 'SET_SORT':
      return { ...state, sort: action.payload }
    case 'SET_FLEXIBLE':
      return { ...state, flexible: action.payload }
    case 'SET_CATEGORIES':
      return { ...state, categories: action.payload }
    case 'SET_CUSTOM_GROUPS':
      return { ...state, customgroups: action.payload }
    case 'SET_SEARCH_BAR_OVERLAY':
      return { ...state, searchBarOverlay: action.payload }
    case 'SET_COMPACT_MODE':
      return {
        ...state,
        compactMode: {
          ...state.compactMode,
          ...action.payload
        }
      }
    case 'RESET_STATE':
      return action.payload
  }
  return state
}

const initParams =
  (query) =>
  ({ sort, searchCriteria } = {}) => {
    // Get checkin and checkout dates
    const newDates = getStartDates(
      query.checkin ? parse(query.checkin, 'yyyy-MM-dd', new Date()) : null,
      query.checkout ? parse(query.checkout, 'yyyy-MM-dd', new Date()) : null
    )

    const featuredCategoryId = searchCriteria.categories?.find(
      (c) => c.slug === query.category
    )?.id

    const categories = []
      .concat(
        query.categories ? query.categories.split('|') : [],
        featuredCategoryId ? String(featuredCategoryId) : null
      )
      .filter((v) => v)

    return {
      sort: sort ?? query.sort,
      checkin: newDates.from,
      checkout: newDates.to,
      bedrooms: query.bedrooms
        ? {
            value: query.bedrooms,
            label: query.bedrooms + '+ Bedrooms'
          }
        : {
            value: 0,
            label: 'Any'
          },
      baths: query.baths
        ? {
            value: query.baths,
            label: query.baths + '+ Baths'
          }
        : {
            value: 0,
            label: 'Any'
          },
      states: query.states
        ? {
            value: query.states,
            label: searchCriteria.states?.find((c) => c.value === query.states)?.label
          }
        : {
            value: '',
            label: 'Any'
          },
      guests: {
        adults: query.adults && query.adults !== '' ? query.adults : 1,
        children: query.children && query.children !== '' ? query.children : 0,
        pets: query.pets && query.pets !== '' ? query.pets : 0
      },
      flexible: query.flexible === 'true',
      page_size: query.page_size ?? null,
      categories,
      customgroups: query.customgroups ? query.customgroups.split(',') : [],
      searchBarOverlay: false,
      compactMode: { desktop: false, tablet: true, mobile: true }
    }
  }

const SearchContextProvider = ({ children, searchCriteria, sortKey }) => {
  const mounted = usePromise()
  const signalPath = useSignalPath()
  const [overlayOffset, setOverlayOffset] = useState()
  const isMobile = useBreakpointValue({ sm: true, md: false })
  const isTablet = useBreakpointValue({
    base: false,
    sm: false,
    md: true,
    lg: false
  })
  const isDesktop = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true
  })

  const router = useRouter()

  // Search params
  const [params, dispatch] = useReducer(
    paramsReducer,
    { searchCriteria },
    initParams(router.query)
  )

  // Keep a reference of the params for when we need to call onSearch
  const paramsRef = useRef(params)
  useEffect(() => {
    paramsRef.current = params
  }, [params])

  const paramsActions = useMemo(
    () => ({
      setBedrooms: (payload) => dispatch({ type: 'SET_BEDROOMS', payload }),
      setBaths: (payload) => dispatch({ type: 'SET_BATHS', payload }),
      setStates: (payload) => dispatch({ type: 'SET_STATES', payload }),
      setGuests: (payload) => dispatch({ type: 'SET_GUESTS', payload }),
      setFlexible: (payload) => dispatch({ type: 'SET_FLEXIBLE', payload }),
      setCategories: (payload) => dispatch({ type: 'SET_CATEGORIES', payload }),
      setCustomgroups: (payload) =>
        dispatch({ type: 'SET_CUSTOM_GROUPS', payload }),
      resetDates: () => dispatch({ type: 'RESET_DATES' }),
      clearSearch: () => dispatch({ type: 'RESET_SEARCH' }),
      setDates: (payload) => dispatch({ type: 'SET_DATES', payload }),
      resetState: (payload) => dispatch({ type: 'RESET_STATE', payload }),
      setSearchBarOverlay: (payload) =>
        dispatch({ type: 'SET_SEARCH_BAR_OVERLAY', payload }),
      setCompactMode: (payload) =>
        dispatch({ type: 'SET_COMPACT_MODE', payload })
    }),
    []
  )

  const dates = useMemo(() => {
    return {
      from: params.checkin,
      to: params.checkout
    }
  }, [params])

  // Keep internal state in sync with query params
  useIsomorphicLayoutEffect(() => {
    paramsActions.resetState(
      initParams(router.query)({
        searchCriteria,
        sort: router.query.sort ?? sortKey
      })
    )
  }, [paramsActions, router.query, searchCriteria, sortKey])

  const onDateChange = (range, selectedDay) => {
    range = selectDateRange(dates, selectedDay)
    const newDates = {
      from: range?.from,
      to: range?.to
    }

    dispatch({ type: 'SET_DATES', payload: newDates })
    return newDates
  }

  useEffect(() => {
    if (params.searchBarOverlay) {
      hideScrollBar(true)
    } else {
      hideScrollBar(false)
    }
  }, [params.searchBarOverlay])

  const onSearch = useCallback(
    async (args = {}, { sortOnly = false } = {}) => {
      const params = { ...paramsRef.current, ...args }
      const {
        checkin,
        checkout,
        bedrooms,
        baths,
        states,
        sort_seed,
        guests,
        categories,
        customgroups,
        sort,
        compactMode,
        searchBarOverlay,
        ...otherParams
      } = params

      const { setDates, setSearchBarOverlay } = paramsActions

      let safeCheckout = checkout

      if (checkin && !checkout) {
        safeCheckout = addDays(checkin, 1)
        setDates({
          from: checkin,
          to: safeCheckout
        })
      }

      const featuredCategoryId = searchCriteria.categories?.find(
        (c) => c.slug === router.query.category
      )?.id

      // If we're on a category page and we're only sorting we stay on the same page
      // so we don't need to add the featured category to the query parameters
      const queryCategories =
        sortOnly && featuredCategoryId
          ? categories.filter((c) => c !== String(featuredCategoryId))
          : categories

      const queryString = omitBy(
        {
          checkin: checkin ? format(checkin, 'yyyy-MM-dd') : null,
          checkout: safeCheckout ? format(safeCheckout, 'yyyy-MM-dd') : null,
          bedrooms: bedrooms.value,
          baths: baths.value,
          states: states.value,
          ...guests,
          sort_seed: sort === 'random' ? sort_seed : null,
          sort,
          categories: queryCategories.join('|'),
          customgroups: customgroups.join(','),
          ...otherParams
        },
        (id) => !id
      )

      let searchUrl = '/search'
      if (sortOnly) {
        if (router.route.indexOf('/feature/[category]') >= 0) {
          searchUrl = `/feature/${router.query.category}`
        } else if (router.route.indexOf('/custom-groups/[slug]') >= 0) {
          searchUrl = `/custom-groups/${router.query.slug}`
        } else if (router.route.indexOf('/complexes/[slug]') >= 0) {
          searchUrl = `/complexes/${router.query.slug}`
        }
      }
      const href = signalPath(searchUrl, { query: queryString })

      // TODO: Need a better loading state
      setSearchBarOverlay(true)
      await mounted(Router.push(href))
      setSearchBarOverlay(false)
    },
    [
      searchCriteria.categories,
      router.query.category,
      signalPath,
      mounted,
      paramsActions
    ]
  )

  const onClearFilters = useCallback(() => {
    onSearch({
      bedrooms: 0,
      baths: 0,
      states: '',
      adults: 1,
      children: 0,
      pets: 0
    })
  }, [onSearch])

  const onSortChanged = useCallback(
    (sort) => {
      onSearch(
        {
          sort
        },
        { sortOnly: true }
      )
    },
    [onSearch]
  )

  const exportedValues = {
    dates,
    onDateChange,
    bedrooms: params.bedrooms,
    baths: params.baths,
    states: params.states,
    guests: params.guests,
    sortKey: params.sort,
    flexible: params.flexible,
    pageSize: params.page_size,
    categories: params.categories,
    customgroups: params.customgroups,
    featuredCategory: params.featuredCategory,
    onSearch,
    onClearFilters,
    onSortChanged,
    searchBarOverlay: params.searchBarOverlay,
    isMobile,
    isTablet,
    isDesktop,
    searchCriteria,
    overlayOffset,
    setOverlayOffset,
    compactMode: params.compactMode,
    ...paramsActions
  }

  return (
    <SearchContext.Provider value={exportedValues}>
      {children}
    </SearchContext.Provider>
  )
}
export default SearchContextProvider

export const useSearchContext = () => {
  const context = useContext(SearchContext)
  if (context === undefined)
    throw new Error('Context must be defined within a provider')
  return context
}

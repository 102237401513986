import { useSearchContext } from '@/contexts/search'
import { useSignalContext } from '@/contexts/signal'
import { getMaxPageHeight, getScrollGapWidth } from '@/lib/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useEvent } from 'react-use'

export default function useStickySearchBar() {
  const {
    setCompactMode,
    compactMode,
    isDesktop,
    isTablet,
    isMobile,
    setOverlayOffset,
    searchBarOverlay
  } = useSearchContext()
  const { standalone, featureFlags } = useSignalContext()
  const [header, setHeader] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState(null)
  const fullHeaderHeight = useRef(0)
  const [stickyProps, setStickyProps] = useState({})
  const [sticky, setSticky] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [elRefs, setElRefs] = useState(false)

  const isCompactMode = useMemo(() => {
    return (
      (isDesktop && compactMode.desktop) ||
      (isTablet && compactMode.tablet) ||
      (isMobile && compactMode.mobile)
    )
  }, [compactMode, isDesktop, isMobile, isTablet])

  const getCurrentScreenState = useCallback(() => {
    let state = []
    if (isDesktop) state.push('desktop')
    if (isTablet) state.push('tablet')
    if (isMobile) state.push('mobile')
    if (isCompactMode) state.push('compact')
    if (!isCompactMode) state.push('full')
    return state
  }, [isCompactMode, isDesktop, isMobile, isTablet])

  const adjustPagePadding = useCallback(
    (padding) => {
      if (!standalone) {
        const beClientWrapper = document.getElementById('signal-be-client')
        if (beClientWrapper) {
          beClientWrapper.style.paddingTop = padding + 'px'
        }
      } else {
        const standaloneWrapper = document.getElementById('standalone_body')
        if (standaloneWrapper) {
          standaloneWrapper.style.paddingTop = padding + 'px'
        }
      }
    },
    [standalone]
  )

  const updateOverlayOffset = useCallback(() => {
    const currentBar = document.getElementById(
      getCurrentScreenState().join('-') + '-searchbar'
    )
    if (currentBar) {
      setOverlayOffset(
        currentBar.offsetHeight + currentBar.getBoundingClientRect().top
      )
    }
  }, [setOverlayOffset, getCurrentScreenState])

  const updateStickyCompact = useCallback(() => {
    if (
      (standalone ||
        (header && header.classList.contains('header-scrolled')) ||
        (!standalone && !header)) &&
      elRefs
    ) {
      const currentCompactBar = document.getElementById(
        getCurrentScreenState()[0] + '-compact-searchbar'
      )
      const breadcrumbsHeight = breadcrumbs ? breadcrumbs.offsetHeight : 0
      const headerHeight = header ? header.offsetHeight : 0
      let stickyCondition = false
      let top = 0
      const headerFullyVisible = header
        ? header.getBoundingClientRect().top >= 0
        : false

      //standalone version
      if (standalone) {
        stickyCondition = isMobile ? true : window.scrollY >= breadcrumbsHeight
        top = headerHeight + 'px'
        //WP version
      } else if (header) {
        if (isDesktop) {
          stickyCondition =
            window.scrollY >=
            fullHeaderHeight.current - headerHeight + breadcrumbsHeight
          top = headerHeight + 'px'
        } else if (isTablet) {
          stickyCondition =
            window.scrollY >=
            (headerFullyVisible
              ? fullHeaderHeight.current - headerHeight
              : headerHeight) +
              breadcrumbsHeight
          top = headerFullyVisible ? headerHeight + 'px' : 0
        } else {
          stickyCondition = window.scrollY >= headerHeight
        }
        //Nextjs only version (mostly for local dev)
      } else {
        stickyCondition = isMobile ? true : window.scrollY >= breadcrumbsHeight
        top = 0
      }

      if (
        featureFlags?.sticky_search_bar &&
        pageLoaded &&
        getMaxPageHeight() > 1500
      ) {
        if (stickyCondition) {
          setSticky(true)
          setStickyProps({
            position: 'fixed',
            top,
            zIndex: 3,
            width: '100%'
          })
          setCompactMode({
            desktop: isDesktop,
            tablet: isTablet,
            mobile: isMobile
          })

          if (currentCompactBar) {
            adjustPagePadding(currentCompactBar.offsetHeight)
          }
        }

        if (!stickyCondition) {
          setSticky(false)
          setStickyProps({})
          if (isDesktop) {
            setCompactMode({ desktop: !isDesktop })
          }
          adjustPagePadding(0)
        }
      }
    }
  }, [
    setCompactMode,
    adjustPagePadding,
    getCurrentScreenState,
    pageLoaded,
    isMobile,
    elRefs,
    isTablet,
    isDesktop,
    breadcrumbs,
    header,
    standalone,
    featureFlags?.sticky_search_bar
  ])

  useEffect(() => {
    setPageLoaded(true)
  }, [])

  useEffect(() => {
    if (pageLoaded) {
      if (!header) {
        const headerEl = document.getElementById('header')
        fullHeaderHeight.current = headerEl ? headerEl.offsetHeight : 0
        setHeader(headerEl)
      }
      if (!breadcrumbs) {
        const breadcrumbsEl = document.getElementById('breadcrumbs')
        setBreadcrumbs(breadcrumbsEl)
      }
      setElRefs(true)
    }
  }, [header, breadcrumbs, pageLoaded])

  useEffect(() => {
    updateOverlayOffset()
  }, [updateOverlayOffset, isCompactMode])

  useEffect(() => {
    if (window.scrollY !== 0) {
      window.scrollTo(0, 0)
    }
    return () => {
      adjustPagePadding(0)
    }
  }, [adjustPagePadding])

  useEffect(() => {
    if (searchBarOverlay && sticky) {
      const gap = getScrollGapWidth().gap
      setStickyProps((sp) => {
        return {
          ...sp,
          width: `calc(100% - ${gap}px)`
        }
      })
    } else {
      setStickyProps((sp) => {
        return {
          ...sp,
          width: '100%'
        }
      })
    }
  }, [searchBarOverlay, sticky])

  useEvent('scroll', () => {
    updateStickyCompact()
  })
  useEvent('resize', () => {
    updateStickyCompact()
  })

  return {
    stickyProps
  }
}

import { useSearchContext } from '@/contexts/search'
import { Box } from '@chakra-ui/react'

export default function SearchOverlay() {
  const { searchBarOverlay, overlayOffset } = useSearchContext()

  if (!searchBarOverlay) {
    return null
  }
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: 0,
        top: overlayOffset + 'px',
        backgroundColor: '#333333',
        opacity: 0.3,
        zIndex: 2
      }}
    />
  )
}

const propertySortOptions = [
  {
    value: 'random',
    label: 'Random'
  },
  {
    value: 'price-lh',
    label: 'Price: Lowest to Highest'
  },
  {
    value: 'price-hl',
    label: 'Price: Highest to Lowest'
  },
  {
    value: 'bedrooms-lh',
    label: 'Bedrooms: Lowest to Highest'
  },
  {
    value: 'bedrooms-hl',
    label: 'Bedrooms: Highest to Lowest'
  },
  {
    value: 'rating',
    label: 'Review Rating'
  },
  {
    value: 'review-cnt',
    label: 'Number of Reviews'
  },
  {
    value: 'propname',
    label: 'Property Name'
  },
  {
    value: 'propname-hl',
    label: 'Property Name Reversed'
  }
]

export default propertySortOptions

import { useSearchContext } from '@/contexts/search'
import StatesIcon from 'icons/location.svg'
import { useTranslation } from 'next-i18next'
import CustomSearchDropdown from './CustomSearchDropdown'

export default function States() {
  const { searchCriteria, states, setStates } = useSearchContext()
  const { t } = useTranslation('search')

  return (
    <CustomSearchDropdown
      Icon={StatesIcon}
      option={states}
      setOption={setStates}
      title={t('states')}
      placeholder={t('states')}
      name="states"
      dropDownOptions={searchCriteria.states}
      minWidth="140px"
    />
  )
}

import { useSearchContext } from '@/contexts/search'
import useSignalPath from '@/hooks/useSignalPath'
import ArrowDown from '@/icons/arrow-down.svg'
import { sortByKey } from '@/lib/utils'
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  Icon,
  useBreakpointValue,
  useToken
} from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'
import Select from 'react-select'

const DropdownIndicator = () => {
  return (
    <Box pr="4">
      <Icon as={ArrowDown} fill="gray.300" />
    </Box>
  )
}

export default function SelectPropertyDropdown({ properties }) {
  const signalPath = useSignalPath()
  const [selectedProperty, setSelectedProperty] = useState()
  const { setSearchBarOverlay, isMobile } = useSearchContext()
  const router = useRouter()
  const [menuOpen, setMenuOpen] = useState(false)
  const dropdownHeight = useBreakpointValue({ base: '40px', md: '48px' })
  const [primaryColor, borderColor] = useToken('colors.wpMain', [
    'primary',
    'border'
  ])
  const [gray800] = useToken('colors.gray', ['800'])
  const { t } = useTranslation('search')

  const customStyles = {
    control: (provided, { selectProps: { minWidth } }) => ({
      ...provided,
      minWidth,
      fontSize: '14px',
      height: dropdownHeight,
      borderColor: menuOpen ? primaryColor : borderColor,
      boxShadow: `none`,
      '&:focus': {
        borderColor: primaryColor
      },
      '&:hover': {
        borderColor: primaryColor
      },
      borderRight: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      outline: 'none'
    }),
    singleValue: (provided, { selectProps: { color } }) => ({
      ...provided,
      color
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 3,
      fontSize: '14px'
    }),
    container: (provided) => ({
      ...provided,
      width: isMobile ? '100%' : 'auto'
    }),
    placeholder: (provided, { selectProps: { color } }) => ({
      ...provided,
      color
    })
  }

  let options =
    properties && properties.length > 0
      ? properties.map((property) => {
          return { value: property.slug, label: property.name }
        })
      : []
  options = sortByKey(options, 'value')

  const goToProperty = () => {
    if (!selectedProperty) {
      return
    }
    const href = signalPath(`/properties/${selectedProperty.value}`)
    router.push(href)
  }

  return (
    <>
      {isMobile && <Divider my="5" orientation="horizontal" />}
      <HStack
        alignItems={'top'}
        justifyContent={'flex-end'}
        py={{
          base: '2',
          md: '2',
          lg: '4'
        }}
        px="0"
        spacing={0}
        w={{ base: 'full', md: 'auto', lg: 'full' }}
      >
        <Center
          height={{ base: '40px', md: '48px' }}
          pr={5}
          display={{ base: 'none', lg: 'block' }}
        >
          <Divider orientation="vertical" color="gray.400" />
        </Center>

        <Select
          styles={customStyles}
          instanceId={'select-property-dropdown'}
          minWidth="190px"
          onMenuOpen={() => {
            setSearchBarOverlay(true)
            setMenuOpen(true)
          }}
          onMenuClose={() => {
            setSearchBarOverlay(false)
            setMenuOpen(false)
          }}
          color={gray800}
          options={options}
          components={{
            IndicatorSeparator: null,
            DropdownIndicator: DropdownIndicator
          }}
          placeholder="Select a property"
          onChange={(item) => setSelectedProperty(item)}
          value={selectedProperty}
        />
        <Button
          height={{ base: '40px', md: '48px' }}
          borderTopLeftRadius="0"
          borderBottomLeftRadius="0"
          size="md"
          className="btn-primary"
          colorScheme="wpHeaderScheme"
          onClick={goToProperty}
        >
          {t('go')}
        </Button>
      </HStack>
    </>
  )
}

import React from 'react'
import { useSearchContext } from '@/contexts/search'
import BedroomIcon from 'icons/bed.svg'
import { useTranslation } from 'next-i18next'
import CustomSearchDropdown from './CustomSearchDropdown'

export default function SearchFields() {
    const { bedrooms, setBedrooms, searchCriteria } = useSearchContext()
    const { t } = useTranslation('search')

    return (
        <CustomSearchDropdown
            Icon={BedroomIcon}
            option={bedrooms}
            setOption={setBedrooms}
            title={t('bedrooms')}
            placeholder={t('bedrooms')}
            name="bedroom"
            dropDownLimit={searchCriteria.bedrooms || 8}
            minWidth="140px"
        />
    )
}

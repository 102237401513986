import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useBreakpointValue
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { AiFillInfoCircle } from 'react-icons/ai'

const IncrementControl = ({
  label,
  onChange,
  value,
  minimumValue,
  maximumValue,
  disabled = false,
  tip,
  ...rest
}) => {
  const size = useBreakpointValue({ base: 'xs', lg: 'sm' })
  return (
    <Box
      display="flex"
      borderRadius="md"
      alignItems="center"
      flex={1}
      px={4}
      h={10}
      justifyContent="space-between"
      {...rest}
    >
      <Flex justifyContent={'center'} alignItems={'center'}>
        <Text fontSize={{ base: 'xs', md: 'md' }} color="gray.600">
          {label}
        </Text>
        {tip && disabled && (
          <Tooltip
            label={tip}
            fontSize="md"
            placeContent={'bottom-end'}
            hasArrow
          >
            <Flex alignItems={'center'}>
              <Icon
                ml="8px"
                boxSize="20px"
                as={AiFillInfoCircle}
                color="gray.600"
              />
            </Flex>
          </Tooltip>
        )}
      </Flex>
      <HStack spacing={[2, 4]} alignItems="center">
        <IconButton
          size={size}
          variant="outline"
          aria-label="Decrement value"
          icon={<MinusIcon />}
          isRound
          disabled={value === 0 || disabled}
          onClick={(evt) => {
            evt.preventDefault()
            if (minimumValue && value <= minimumValue) {
              return
            }
            onChange(value - 1)
          }}
        />
        <Center w={{ base: 2, lg: 4 }}>
          <Text fontSize={{ base: 'xs', md: 'md' }}>{value}</Text>
        </Center>
        <IconButton
          size={size}
          aria-label="Increment value"
          icon={<AddIcon />}
          isRound
          disabled={disabled}
          onClick={(evt) => {
            evt.preventDefault()
            if (maximumValue && value >= maximumValue) {
              return
            }
            onChange(value + 1)
          }}
        />
      </HStack>
    </Box>
  )
}

IncrementControl.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

export default IncrementControl

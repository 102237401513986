import { CustomCalendarCaptionLabel } from '@/components/core/CustomCalendarCaptionLabel'
import { FullScreenCalendar } from '@/components/core/FullScreenCalendar'
import IconTriggerButton from '@/components/core/IconTriggerButton'
import { useSearchContext } from '@/contexts/search'
import { useSettingContext } from '@/contexts/settings'
import { useSignalContext } from '@/contexts/signal'
import { getLocaleStartOfWeek, localeFromISODate } from '@/lib/localeUtils'
import { selectDateRange } from '@/lib/utils'
import { InfoIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  Tooltip,
  VStack
} from '@chakra-ui/react'
import { add, format } from 'date-fns'
import CalendarIcon from 'icons/calendar.svg'
import { useTranslation } from 'next-i18next'
import React, { useEffect, useState } from 'react'
import { DayPicker } from 'react-day-picker'

export default function Dates() {
  const { isMobile } = useSearchContext()

  if (isMobile) {
    return <MobileDates />
  }

  return <DesktopTabletDates />
}

const DatePickerButtonGroup = React.forwardRef(
  ({ dates, isOpen, borderRadius, ...rest }, ref) => {
    const { locale } = useSignalContext()
    const { isMobile } = useSearchContext()
    const { t } = useTranslation('search')

    const fieldCommonProps = (input) => {
      return {
        h: '48px',
        minWidth: '135px',
        borderColor: isOpen ? 'wpMain.primary' : 'wpMain.border',
        titleSize: !input ? (!isMobile ? 'sm' : 'md') : 'xs',
        padding: 3,
        contentSize: !isMobile ? 'sm' : 'md',
        colors: {
          icon: 'gray.500',
          title: 'gray.800',
          content: 'gray.800'
        },
        content: getDateContent(input, locale),
        LeftIcon: !isMobile ? CalendarIcon : null,
        flex: 1,
        justifyContent: 'flex-start'
      }
    }

    DatePickerButtonGroup.displayName = 'DatePickerButtonGroup'

    const getDateContent = (date, locale) => {
      return date
        ? localeFromISODate(format(new Date(date), 'yyyy-MM-dd'), locale)
        : ''
    }

    return (
      <ButtonGroup
        ref={ref}
        backgroundColor="white"
        w="full"
        p="0"
        variant="outline"
        isAttached
        {...rest}
      >
        <IconTriggerButton
          title={!isMobile || (isMobile && !dates.from) ? t('checkin') : null}
          borderRight="0"
          borderRadius={borderRadius.from}
          {...fieldCommonProps(dates.from)}
        />
        <IconTriggerButton
          title={!isMobile || (isMobile && !dates.to) ? t('checkout') : null}
          borderRadius={borderRadius.to}
          {...fieldCommonProps(dates.to)}
        />
      </ButtonGroup>
    )
  }
)

const DesktopTabletDates = () => {
  const { t } = useTranslation('search')
  const { locale } = useSignalContext()
  const { settings } = useSettingContext()
  const {
    dates,
    onDateChange,
    resetDates,
    setSearchBarOverlay,
    isMobile,
    flexible,
    setFlexible,
    clearSearch
  } = useSearchContext()
  const [month, setMonth] = useState(dates.from)

  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const popoverStyling = isMobile ? {} : { minWidth: 625 }

  useEffect(() => {
    if (!isOpen) setMonth(dates.from)
  }, [dates, isOpen])

  const onSelect = (range, selectedDay) => {
    const newDates = onDateChange(range, selectedDay)

    const hasKeys = !!newDates.from && !!newDates?.to
    if (hasKeys && newDates.from !== newDates.to) {
      setIsOpen(false)
      setSearchBarOverlay(false)
    }
  }

  const startDayOfWeek = getLocaleStartOfWeek(locale)

  return (
    <Box flex="2">
      <Popover
        onOpen={() => {
          onOpen()
          setSearchBarOverlay(true)
        }}
        onClose={() => {
          onClose()
          setSearchBarOverlay(false)
        }}
        isOpen={isOpen}
        placement="bottom"
        variant="responsive"
        trigger="click"
        matchWidth={true}
      >
        <PopoverTrigger>
          <DatePickerButtonGroup
            dates={dates}
            isOpen={isOpen}
            borderRadius={{
              from: '5px 0 0 5px',
              to: '0'
            }}
          />
        </PopoverTrigger>
        <PopoverContent sx={popoverStyling}>
          <DayPicker
            mode="range"
            weekStartsOn={startDayOfWeek}
            selected={dates}
            fromDate={add(new Date(), { days: +settings.earliestCheckin })}
            toDate={add(new Date(), {
              months: +settings.popupCalendarMaxMonths
            })}
            onSelect={onSelect}
            components={{
              CaptionLabel: CustomCalendarCaptionLabel
            }}
            month={month}
            onMonthChange={setMonth}
            numberOfMonths={isMobile ? 1 : 2}
          />
          <Divider color="gray.200" />
          <Flex p={5} w="full">
            <Spacer />
            <VStack spacing={1}>
              <Center h={6}>
                <Checkbox
                  sx={{
                    '.chakra-checkbox__label': {
                      fontSize: 'sm',
                      textDecor: 'underline'
                    }
                  }}
                  isChecked={flexible}
                  onChange={(evt) => setFlexible(evt.target.checked)}
                >
                  {t('flexible')}
                </Checkbox>
              </Center>
              <HStack spacing={1}>
                <InfoIcon color="gray.300" boxSize={3.5} />
                <Text fontSize="xs" color="gray.600">
                  {t('flexible-help')}
                </Text>
              </HStack>
            </VStack>
            <Flex direction="row-reverse" flex={1}>
              <Button
                size="xs"
                fontWeight="normal"
                variant="ghost"
                onClick={resetDates}
              >
                {t('clear-dates')}
              </Button>
            </Flex>
          </Flex>
        </PopoverContent>
      </Popover>
      <HStack px={1} py={3} justifyContent="space-between">
        <Center h={6}>
          <Checkbox
            sx={{
              '.chakra-checkbox__label': {
                fontSize: 'xs',
                textDecor: 'underline'
              }
            }}
            isChecked={flexible}
            onChange={(evt) => setFlexible(evt.target.checked)}
          >
            <Tooltip
              hasArrow
              placement="top"
              label={t('flexible-help')}
              borderRadius="md"
              px={4}
              fontSize="xs"
              maxW="sm"
              py={3}
            >
              {t('flexible')}
            </Tooltip>
          </Checkbox>
        </Center>
        <Button
          size="xs"
          onClick={clearSearch}
          fontWeight="normal"
          variant="ghost"
        >
          {t('clear-search')}
        </Button>
      </HStack>
    </Box>
  )
}

const MobileDates = () => {
  const {
    onDateChange,
    dates,
    resetDates,
    flexible,
    setFlexible,
    clearSearch
  } = useSearchContext()
  const { locale } = useSignalContext()

  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('search')

  const onSelect = (range, selectedDay) => {
    onDateChange(selectDateRange(dates, selectedDay), selectedDay)
  }

  useEffect(() => {
    if (dates.from && dates.to) {
      setIsOpen(false)
    }
  }, [dates])

  return (
    <Box>
      <DatePickerButtonGroup
        dates={dates}
        isOpen={isOpen}
        onClick={() => setIsOpen(true)}
        borderRadius={{
          from: '5px 0 0 5px',
          to: '0 5px 5px 0'
        }}
      />

      <HStack px={1} py={3} justifyContent="space-between">
        <Center h={6}>
          <Checkbox
            sx={{
              '.chakra-checkbox__label': {
                fontSize: 'xs',
                textDecor: 'underline'
              }
            }}
            isChecked={flexible}
            onChange={(evt) => setFlexible(evt.target.checked)}
          >
            <Tooltip
              hasArrow
              placement="top"
              label={t('flexible-help')}
              borderRadius="md"
              px={4}
              fontSize="xs"
              maxW="sm"
              py={3}
            >
              {t('flexible')}
            </Tooltip>
          </Checkbox>
        </Center>
        <Button
          size="xs"
          onClick={clearSearch}
          textDecoration="underline"
          fontWeight="normal"
          variant="ghost"
        >
          {t('clear-search')}
        </Button>
      </HStack>

      <FullScreenCalendar
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onSelect={onSelect}
        dates={dates}
        resetDates={resetDates}
        t={t}
        locale={locale}
      />
    </Box>
  )
}
